import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListEverbridgeIntegrationsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listEverbridgeIntegrationsDocument = graphql(/* GraphQL */ `
	query listEverbridgeIntegrations($params: ListEverbridgeIntegrationsParams!) {
		listEverbridgeIntegrations(params: $params) {
			_id
			api_key
			created
			default_vcc_category
			disabled
			name
			updated
		}
	}
`);

interface Args {
	params: ListEverbridgeIntegrationsParams;
	userId?: string;
}

const listEverbridgeIntegrations = ({ userId, params }: Args) =>
	client(userId)
		.request(listEverbridgeIntegrationsDocument, { params })
		.then((res) => res.listEverbridgeIntegrations);

const useListEverbridgeIntegrations = (
	params: ListEverbridgeIntegrationsParams
) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	const listEverbridgeIntegrationsQueryKey =
		createListEverbridgeIntegrationsQueryKey(userId, _id, params);

	return useQuery(
		listEverbridgeIntegrationsQueryKey,
		() => listEverbridgeIntegrations({ params, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId,
			onError: (err) => handleError(err),
			staleTime: 5 * 60 * 1000,
		}
	);
};

export default useListEverbridgeIntegrations;

export const createListEverbridgeIntegrationsQueryKey = (
	userId: string | undefined,
	_id: string | undefined,
	params?: ListEverbridgeIntegrationsParams
) => {
	if (params) {
		return [
			"user",
			userId,
			"account",
			_id,
			"listEverbridgeIntegrations",
			params,
		];
	} else return ["user", userId, "account", _id, "listEverbridgeIntegrations"];
};
