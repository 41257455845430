import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetEverbridgeIntegrationParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const getEverbridgeIntegrationDocument = graphql(/* GraphQL */ `
	query getEverbridgeIntegration($params: GetEverbridgeIntegrationParams!) {
		getEverbridgeIntegration(params: $params) {
			api_key
			disabled
			default_vcc_category
			event_mapping {
				event_type
				severity_level
				vcc_category
			}
			name
			social_types {
				twitter
				web
			}
			url
			version
		}
	}
`);

interface Args {
	params: GetEverbridgeIntegrationParams;
	userId?: string;
}

const getEverbridgeIntegration = ({ userId, params }: Args) =>
	client(userId)
		.request(getEverbridgeIntegrationDocument, { params })
		.then((res) => res.getEverbridgeIntegration);

const useGetEverbridgeIntegration = (
	params: GetEverbridgeIntegrationParams
) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	const getEverbridgeIntegrationQueryKey =
		createGetEverbridgeIntegrationQueryKey(userId, _id, params);

	return useQuery(
		getEverbridgeIntegrationQueryKey,
		() => getEverbridgeIntegration({ params, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId,
			onError: (err) => handleError(err),
			staleTime: 5 * 60 * 1000,
		}
	);
};

export default useGetEverbridgeIntegration;

export const createGetEverbridgeIntegrationQueryKey = (
	userId: string | undefined,
	_id: string | undefined,
	params: GetEverbridgeIntegrationParams
) => {
	return ["user", userId, "account", _id, "getEverbridgeIntegration", params];
};
